@import '../../../libs/scss/index.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
	@apply h-full text-dark-max antialiased;
}
